.fp_testcase_input_record_container {
  padding: 30px 25px 0 25px;
  width: 100%;
  textarea {
    background-color: $white-20;
    color: $white;
    border-radius: 18px;
    padding: 10px;
    width: 100%;
  }
}

.fp_input_record_btn_container {
  width: 100%;
  padding: 0 25px;
}

.fp_textinfo_rcrd{
  font-size: 14px !important;
  font-weight: 400 !important;
  width: 100%;
  text-align: center;
  padding: 1px;
  margin-bottom: 5px;
}
.fp_textinfo_ttl{
  font-size: 16px !important;
  font-weight: 600 !important;
  width: 100%;
  text-align: center;
  padding: 1px;
}
.ft_textinfo_col{
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-align: center;
}
.fp_pst_btn_rw{
  display: flex;
  float: right !important;
  padding-top: 20px;
  @media (max-width: 992px) {
    width: 100% !important;
    display: flex;
  }
}
.fp_navigate_gnrt_btn{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: 1200px) {
    justify-content: center;
  }
}
.fp_responsive_btn {
  @media (max-width: 992px) {
    width: 100% !important;
    .ant-upload {
      width: 100%;
    }
  }
}
.fp_generate_btn{
  font-size: 14px !important;
  //padding: 5px !important;
  width: 100% !important;
  font-weight: 600 !important;
  text-align: center !important;
}
.fp_btn_wrap {
  display: flex;
  flex-flow: wrap;
}

.fp_switch.ant-switch-checked {
  background-color: $base-color-3 !important;
}

.red_btn {
  background: $base-color-2 !important;
  background-color: $base-color-2 !important;
  border: transparent;
  &:hover {
    background: $base-color-3 !important;
    background-color: $base-color-3 !important;
  }
}
.fp_status-title-name{
  font-size: 24px !important;

  &-feedback {
    font-size: 20px !important;
    font-weight: 800;
    padding-bottom: 10px !important;
  }
}

.fp_logview_editor {
  @include themify {
    background: themed('background-opcity-40') !important;
  }
  font-size: 15px !important;
  border-radius: 20px !important;
  font-family: ui-monospace, SFMono-Regular, SF Mono,Consolas, Liberation Mono, Menlo, monospace !important;
  overflow-y: auto !important;

  &-full-width {
    width: 100%;
  }
}