// full page size
.fp_pg_full {
  width: 100%;
  height: 100vh;
}

//full page height
.fp_pg_hght_full {
  height: 100vh;
}

.fp_width_full {
  width: 100%;
}

//page content base
.fp_pg_base {
  padding: 10px 26px;
  height: 100%;

  @media (max-width: 550px) {
    padding: 20px;
  }
}

//page backgrounds
.fp_pg_bckgrnd_trnsprnt {
  background-color: $transparent !important;
}

.fp_pg_cntnt_bckgrnd {
  @include themify {
    background-color: themed('background-content-opacity');
    // backdrop-filter: blur(5px);
    backdrop-filter: blur(0.2);
  }
}

.fp_pg_cntnt {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // -ms-overflow-style: none;
  // scrollbar-width: none;
}

.yellow {
  color: $state-color-4;
}

.green {
  color: $state-color-3;
}

.red {
  color: $state-color-2;
}

.blue {
  color: $state-color-1;
}

//side bar styles
.fp_sd_pnl {
  @include themify {
    background-color: themed('background-menu-opacity-20');
    backdrop-filter: blur(25px); //not work with firefox
    color: $black;

    .ant-layout-sider-children {
      .fp_dshrd_logo_cntnr {
        width: 100%;
        display: flex;
        margin: 25px 0 70px;
        justify-content: center;
        align-items: center;

        .fp_white_logo {
          color: $white;
        }

        .fp_small_logo {
          color: $white;
          display: none;
        }
      }

      ul {
        background-color: $transparent !important;
        padding: 0 8px !important;

        .ant-menu-item {
          color: $white;
          border-radius: 12px !important;
          padding-left: 0;
        }

        .ant-menu-item-selected {
          background: $linear-gradient-purple-green;
        }

        .ant-menu-item-active:not(.ant-menu-item-selected) {
          background: $linear-gradient-purple-green-50;
        }
      }
    }

    .ant-layout-sider-trigger {
      background-color: themed('background-menu-opacity-20');
    }
  }
}

.ant-layout-sider-collapsed {
  .ant-layout-sider-children {
    .fp_dshrd_logo_cntnr {
      width: 60% !important;
      margin: 10px auto !important;

      .fp_white_logo {
        color: $white;
        display: none;
      }

      .fp_small_logo {
        color: $white;
        display: contents !important;
      }
    }

    ul {
      .ant-menu-item {
        color: $white;
        border-radius: 25px !important;
        padding-left: 25px !important;
        max-width: max-content !important;
      }
    }
  }
}

//header styles
.fp_main_header {
  @include themify {
    background-color: rgba(0, 0, 0, 0.2);
    padding: auto 0;

    .fp_hdr_cntnr {
      display: flex;
      height: 100%;
      max-width: 100%;
      align-items: center;
      justify-content: space-between;

      .fp_hdr_lft_cntnr {
        display: flex;
        float: left;
        height: 100%;
        align-items: center;
        width: max-content;

        .fp_slctr_cntnr {
          height: 100%;
          display: flex;
          align-items: center;

          .fp_slctr_lbl {
            margin-right: 10px;
          }

          .fp_slctr {
            min-width: 200px;

            .ant-select-selector {
              border-radius: 25px;
              background-color: themed('background-menu-opacity-20');

              span {
                background-color: transparent;

                input {
                  background-color: transparent;
                }
              }

              .ant-select-selection-item {
                color: $white;
              }
            }

            .ant-select-arrow {
              color: $white;
            }
          }
        }
      }

      .fp_hdr_rght_cntnr {
        display: flex;
        float: right;
        height: 100%;
        align-items: center;
        width: max-content;

        .fp_hdr_innr_cntnr {
          display: flex;
          flex-direction: row;
          line-height: normal;

          .fp_hdr_sttng {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;

            span {
              font-size: 20px;
            }
          }

          .fp_hdr_prfl {
            background-color: themed('background-menu-opacity-20');
            line-height: normal;
            border-radius: 25px;
            padding: 10px 12px;

            .fp_hdr_prfl_cntnr {
              .fp_hdr_prfl_name {
                color: $white !important;
                background-color: $grey-1 !important;
              }

              .fp_hdr_prfl_ttl {
                @extend .fp_small-text;
              }
            }
          }
        }
      }
    }
  }
}

.fp_slctr_cntnr {
  @include themify {
    height: 100%;
    display: flex;
    align-items: center;

    .fp_slctr_lbl {
      margin-right: 10px;
    }

    .fp_slctr {
      min-width: 200px;

      .ant-select-selector {
        border-radius: 25px;
        background-color: themed('background-menu-opacity-20');

        span {
          background-color: transparent;

          input {
            background-color: transparent;
          }
        }

        .ant-select-selection-item {
          color: $white;
        }
      }

      .ant-select-arrow {
        color: $white;
      }
    }
  }
}

.fp_base_container {
  overflow-y: auto;
}

// align component middle of the page.
.fp_pg_center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.fp_full_size {
  width: 100%;
  height: 100%;
}

//basic styles
.fp_cmpnt {
  display: flex;

  &_cntr {
    // center component on container
    justify-content: center !important;
    align-items: center !important;
  }

  &_flx_rw {
    // set direction row on container
    flex-direction: row !important;
  }

  &_flx_clmn {
    flex-direction: column !important; // set direction column on container
  }

  &_jsfy_cntn_cntr {
    justify-content: center !important;
  }

  &_algn_itms_cntr {
    align-items: center !important;
  }

  &_jsfy_cntn_spc_btwn {
    justify-content: space-between !important;
  }

  &_jsfy_cntn_flx_end {
    justify-content: flex-end !important;
  }
}

//margins
.fp_mrgn_tp_20 {
  margin-top: 20px;
}

.fp_mrgn_tp_30 {
  margin-top: 30px;
}

.fp_mrgn_rght_10 {
  margin-right: 10px;
}

//All heading sizes
.fp_heading-1 {
  font-size: $heading-fontsize-1 !important;
  font-weight: 600 !important;
}

.fp_heading-2 {
  font-size: $heading-fontsize-2 !important;
  font-weight: 600 !important;

  &-middle {
    padding-top: 20px !important;
    margin: auto !important;
  }

  &-left {
    padding-top: 20px !important;
    margin-left: 0px !important;
  }
}

.fp_heading-3 {
  font-size: $heading-fontsize-3 !important;
  font-weight: 600;
}

.fp_heading-4 {
  font-size: $heading-fontsize-4 !important;
  font-weight: 600;
}

.fp_large-text-1 {
  font-size: $large-text-fontsize-1 !important;
  font-weight: 700 !important;
}

.fp_large-text-2 {
  font-size: $large-text-fontsize-2 !important;
  font-weight: 400 !important;
}

.fp_medium-text-1 {
  font-size: $medium-text-fontsize-1 !important;
  font-weight: 500 !important;

  @media (max-width: 360px) {
    font-size: 12px !important;
  }
}

.fp_medium-text-2 {
  font-size: $medium-text-fontsize-2 !important;
  font-weight: 500 !important;

  @media (max-width: 360px) {
    font-size: 10px !important;
  }

  &-middle {
    padding-top: 10px !important;
    margin: auto !important;
  }

  &-secondary{
    font-weight: 100 !important;
  }

  &-ternary{
    font-weight: 700 !important;
    font-size: 20px !important;
  }
}

.fp_regular-text-1 {
  font-size: $regular-text-fontsize-1 !important;
  font-weight: 400 !important;
}

.fp_regular-text-2 {
  font-size: $regular-text-fontsize-2 !important;
  font-weight: 400 !important;
}

.fp_small-text {
  font-size: $small-text-fontsize !important;
  font-weight: 300 !important;
}

.fp_err_text {
  @include themify {
    color: $error-red !important;
  }
}

.fp_scss_text {
  @include themify {
    color: $success-green !important;
  }
}

//basic tag font styles
p,
button,
label {
  @extend .fp_medium-text-2;
  color: $white;
  margin-bottom: 0;

  @include themify {
    color: themed('defult-text-color');
  }
}

// modal styles
.fp-modal-edit-test-case {
  .ant-modal-content {
    background-color: #222222;
    border-radius: 20px !important;
    border-color: $transparent !important;

    @include themify {
      background-color: themed('model-color');
      backdrop-filter: blur(25px);
    }
  }
}

// modal styles
.fp-modal {
  .ant-modal-content {
    background-color: #222222;
    border-radius: 20px !important;
    border-color: $transparent !important;
    text-align: left !important;

    span {
      color: $white;
    }

    color: $white !important;

    @include themify {
      background-color: themed('model-color');
      backdrop-filter: blur(25px);
    }
  }

  .ant-modal-footer {
    border-top: none;
  }
}

//button styles
.fp_btn {
  border-radius: 12px !important;
  height: 40px;
  box-shadow: 0px 4px 4px rgba(67, 0, 52, 0.05);
  transition: none;
  border: 2px solid;

  &-primary {
    background: $linear-gradient-purple-green-50;
    border-color: $purple;

    span {
      color: #fff !important;
    }

    &:hover {
      border-color: $purple;
      background: $linear-gradient-purple-green;
    }

    &:focus {
      background: $linear-gradient-purple-green;
    }

    &:disabled {
      background-color: $base-color-1 !important;
      opacity: 0.5 !important;
    }
  }

  &-secondary {
    background: rgba(132, 78, 224, 0.7) !important;
    border-color: $purple !important;

    span {
      color: $white !important;
    }

    &:hover,
    &:focus {
      background: $purple !important;
    }

    &:disabled {
      background-color: $disable-1 !important;
      border: none !important;

      span {
        color: $white !important;
      }

      &:hover {
        background: $disable-1 !important;
      }
    }
  }

  &-tertiary {
    margin-left: 45%;
    background: rgba(132, 78, 224, 0.7) !important;
    border-color: $purple !important;

    span {
      color: $white !important;
    }

    &:hover,
    &:focus {
      background: $purple !important;
    }

    &:disabled {
      background-color: $model-color !important;
      border-color: $disable-1 !important;

      span {
        color: $white-50 !important;
      }
    }
  }

  &-quaternary {
    background: $primaryTransparent !important;
    border-color: $primaryTransparent1 !important;

    span {
      color: $white !important;
    }

    &:hover,
    &:focus {
      background: $purple !important;
    }

    &:disabled {
      background-color: $disable-1 !important;
      border: none !important;

      span {
        color: $white !important;
      }

      &:hover {
        background: $disable-1 !important;
      }
    }

  }

  &-submit-button {
    background: $linear-gradient-purple-green-50;
    border-color: $transparent !important;

    span {
      color: #fff !important;
    }

    &:hover {
      border-color: $purple;
      background: $linear-gradient-purple-green;
    }

    &:focus {
      background: $linear-gradient-purple-green;
      border: none !important;
    }

    &:disabled {
      background-color: $white-50 !important;
      opacity: 0.5 !important;
      border-color: $disable-1 !important;
      color: $white !important;
    }
  }

  &-device-button {
    border-color: $purple !important;
    width: 100% !important;
    height: 100px;
    display: flex;
    column-gap: 30px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ant-btn {
      height: 50 !important;
    }

    span {
      color: $white !important;
    }

    &:hover {
      background: $linear-gradient-purple-green !important;
      color: $white !important;
    }

    &n:hover,
    &:focus {
      color: $white !important;
    }

    &:disabled {
      color: $white !important;

      span {
        color: $white !important;
      }

      border-color: $white !important;
      background-color: $transparent !important;
      opacity: 0.5;
    }
  }

  &-browser-select-button {
    position: absolute !important;
    width: 90% !important;
    height: 120px;
    column-gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $white-20;
    border: 1.5px solid;
    border-color: $purple !important;

    .ant-btn {
      height: 50 !important;
    }

    span {
      color: $white !important;
    }

    &n:hover,
    &:focus {
      color: $white !important;
    }

    &:hover {
      background: $linear-gradient-purple-green !important;
    }

    &:focus {
      background: $linear-gradient-purple-green-50 !important;
      color: $white !important;
    }

    &:disabled {
      color: $white !important;

      span {
        color: $white !important;
      }

      border-color: $white !important;
      background-color: $transparent !important;
      opacity: 0.5;
    }
  }

  &-outline {
    background: $white-10;
    border: 1.5px solid $purple !important;

    span {
      color: $white !important;
      margin-left: 0;
    }

    &:hover:not(:focus) {
      background: $white-20;
      border: 1.5px solid $purple !important;
    }

    &n:hover,
    &:focus {
      border-color: $purple !important;
      background: $white-20;
    }

    &:focus:not(:hover) {
      background: $white-10;
      color: $black !important;

      span {
        color: $white !important;
      }

    }

    &:disabled {
      color: $white !important;

      span {
        color: $white !important;
      }

      border-color: $white !important;
      background-color: $transparent !important;
      opacity: 0.5;
    }
  }

  &-browser-option{
    width: 50px !important;
    height: 36px !important;
    background: $white-10 !important;
    border-color: $white-20 !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 10px 0 0 5px;

    span {
      color: $white !important;
    }

    &:hover, &:focus {
      background: $purple !important;
    }

    &:disabled {
      background-color: $disable-1 !important;
      border: none !important;

      span {
        color: $white !important;
      }

      &:hover {
        background: $disable-1 !important;
      }
    }
  }

  &-view {
    width: 92px !important;
    height: 28px !important;
    border-radius: 8px !important;
    background: $purple !important;
    border: 2px solid $purple !important;
    justify-content: center !important;
    padding: 2px 15px !important;
    cursor: pointer;

    &:hover,
    &:focus {
      background: $purple !important;
    }

    &-pause_button {
      width: 80px !important;
      background: rgba(231, 53, 80, 1) !important;
      border: none !important;
      font-size: 12px !important;
      padding: 2px 7px !important;

      &:hover,
      &:focus {
        background: rgba(231, 53, 80, 1) !important;
      }

      svg {
        vertical-align: text-top;
        color: $white;
        margin-right: 4px;
      }
    }

    &-resume_button {
      width: 90px !important;
      background: rgba(34, 169, 134, 1) !important;
      border: none !important;
      font-size: 12px !important;
      padding: 2px 7px !important;

      &:hover,
      &:focus {
        background: rgba(34, 169, 134, 1) !important;
      }

      svg {
        vertical-align: text-top;
        color: $white;
        margin-right: 4px;
      }
    }
  }

  &-analytics {
    background: $linear-gradient-purple-green-50 !important;
    width: 146px !important;
    border: none !important;

    svg {
      vertical-align: text-top;
      color: $white;
      margin-right: 8px;
    }

    span {
      vertical-align: text-top;
      color: $white !important;
      font-size: 14px !important;
      font-weight: 500 !important;
    }

    &:hover,
    &:focus {
      background: $linear-gradient-purple-green !important;
    }
  }

  &-refresh-btn {
    background: $linear-gradient-purple-green-50 !important;
    border: none !important;
    width: 146px !important;

    svg {
      vertical-align: text-top;
      color: $white;
      margin-top: 3px;
    }

    span {
      vertical-align: text-top;
      color: $white !important;
      font-size: 14px !important;
      font-weight: 500 !important;
    }

    &:hover,
    &:focus {
      background: $linear-gradient-purple-green !important;
    }
  }


  &-long {
    width: 75% !important;
  }

  &-login {
    width: 100% !important;
  }

  &-link {
    border: 0 transparent;
    background-color: transparent;
    padding: 0 5px !important;
    margin: 0 0 !important;
    height: max-content;
    text-decoration: underline;

    @include themify {
      color: $base-color-2 !important;

      span {
        color: $base-color-2 !important;
      }

      &:hover {
        background-color: transparent;
        text-decoration: none;

        span {
          color: $base-color-3 !important;
        }
      }

      &:disabled {
        background-color: transparent;

        span {
          color: $base-color-3 !important;
          opacity: 0.5;
        }
      }
    }
  }

  &-pcancel {
    margin-right: 20px !important;
  }
}

.chart-option-btn{
  width: 50px !important;
  height: 36px !important;
  background: $white-10 !important;
  justify-content: center !important;
  align-items: center !important;
  border: 'none' !important;
  border-color: $white-10 !important;

  span {
    color: $white !important;
  }

  &:hover, &:focus {
    background: $purple !important;
  }

  &-primary{
    background: $linear-gradient-purple-green-50 !important;
    border-radius: 6px !important;
    margin-right: 10px !important;

    svg {
      vertical-align: text-top;
      color: $white;
      margin-left: -2px !important;
    }

    &:hover, &:focus {
      background: $linear-gradient-purple-green !important;
    }
  }
  &-radius-left{
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }
  &-radius-right{
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
}


.back_btn {
  float: left !important;
  padding: 0 !important;
  width: 85px;
  height: 30px !important;
  background: $white-10 !important;
  border: none !important;

  &:hover {
    background: $white-20 !important;
    text-decoration: none;
  }
}

.fp_lnk {
  @extend .fp_medium-text-2;
  color: $base-color-2 !important;
  padding: 0 10px;

  &:hover {
    color: $base-color-3 !important;
  }
}

.fp_lgn_frm {
  width: 500px;
  padding: 40px 50px;
  border-radius: 25px;
  background-color: $black-40;
  backdrop-filter: blur(50px); //sometime not compatible to browsers

  @include themify {
    background-color: themed('background-opcity-40') !important;
  }

  div {
    margin-bottom: 2px !important;

    .ant-form-item-label {
      label {
        @extend .fp_medium-text-2;

        @include themify {
          color: themed('defult-text-color') !important;
        }
      }
    }

    div {
      div {
        div {
          display: flex;
          justify-content: center;
          align-items: center;

          @include themify {
            input {
              border-radius: 20px;
              background-color: themed('input-background-20') !important;
              color: themed('input-text-color') !important;

              &:focus {
                background: themed('input-background-20') !important;
                color: themed('input-text-color') !important;
              }
            }

            &>span:not(.ant-spin-dot) {
              border-radius: 20px;
              background: themed('input-background-20') !important
            }

            &>span>input {
              border-radius: 20px;
              background: transparent !important;
              color: themed('input-text-color') !important;

              &:focus {
                background: transparent !important;
                color: themed('input-text-color') !important;
              }
            }

            &>span>span>span {
              color: $white !important;
            }
          }
        }
      }

      div {
        div {
          @extend .fp_medium-text-2;
          color: $error-red !important;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 550px) {
    width: 350px;
  }

  @media (max-width: 360px) {
    width: 300px;
    padding: 30px 30px;
  }

  @media (max-width: 280px) {
    width: 240px;
    padding: 30px 20px;
  }
}

//page loader style
.fp_spin_cntnr {
  height: 100px;
  width: 100px;
  border-radius: 15px;

  @include themify {
    background-color: themed('background-opcity-40');
    backdrop-filter: blur(25px);

    .ant-spin {
      margin-right: 0 !important;

      span {
        i {
          background-color: themed('defult-text-color') !important;
        }
      }
    }
  }
}

//list styles
.fp_list {
  background-color: transparent;
  margin-top: 50px;
  overflow-x: auto !important;
  max-width: 100%;

  @include themify {
    .fp_list_title {
      padding: 15px 30px;
      border-radius: 25px;
      margin: 5px 0;
      box-shadow: 5px;
      min-width: 500px;
    }

    .fp_list_title_new {
      padding: 12px 16px;
      border-radius: 10px;
      margin: 5px 0;
      box-shadow: 5px;
      min-width: 500px;
      border: 1px solid $white-20;
      background: $white-20 !important;
      height: 45px !important;
    }

    .fp_list_item {
      min-width: 500px;
      background-color: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(25px);
      padding: 15px 30px;
      border-radius: 25px;
      margin: 5px 0;
      box-shadow: 5px;

      .ant-tag {
        background: $purple;
        border-radius: 8px;
        padding: 2px 6px;
        color: $white;
      }

      &:hover {
        cursor: pointer;
      }

      div {
        .fp_list_item_more_icon {
          color: themed('defult-text-color');
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .fp_list_item_new {
      color: $white !important;
      min-width: 500px;
      background-color: rgba(255, 255, 255, 0.1) !important;
      backdrop-filter: blur(25px);
      padding: 8px 0px 11px 13px;
      min-height: 45px !important;
      border-radius: 10px;
      margin: 5px 0;
      box-shadow: 5px;
      border: 1px solid $white-20;
      align-items: center;
      cursor: auto !important;

      .ant-tag {
        background: $purple;
        border-radius: 8px;
        padding: 2px 6px;
        color: $white;
      }

      &:hover {
        cursor: pointer;
      }

      div {
        .fp_list_item_more_icon {
          color: themed('defult-text-color');
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}

.fp_search {
  @include themify {
    max-width: 300px;
    background-color: $transparent;

    .ant-input-wrapper {
      border-radius: 25px;
      background-color: themed('background-menu-opacity-20');
      border: 1px $white solid;

      input {
        border-radius: 25px;
        background-color: $transparent;
        border: none;
        color: themed('defult-text-color');

        &:focus() {
          box-shadow: none;
        }
      }

      .ant-input-group-addon {
        border-radius: 0 25px 25px 0 !important;
        background-color: $transparent;

        .ant-input-search-button {
          border: none;
          border-radius: 0 25px 25px 0 !important;
          background-color: $transparent;
          animation: none !important;
        }
      }
    }
  }
}

.fp_nw_tstcs_tile_cntnr {
  .fp_nw_tstcs_tile {
    @include themify {
      padding: 70px 50px;

      .fp_tile_icon_cntnr {
        background-color: #ff0;
        cursor: pointer;
        padding: 10vh 8vw;
        border-radius: 25px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;

        .fp_tile_icon {
          font-size: 35px;
          margin-bottom: 15px;
        }
      }

      .fp_tile_desc {
        color: $white;
        max-width: fit-content;
      }

      &.bg-1 {
        .fp_tile_icon_cntnr {
          background-color: $base-color-3;
        }
      }

      &.bg-2 {
        .fp_tile_icon_cntnr {
          background-color: $base-color-2;
        }
      }
    }

    @media (max-width: 550px) {
      padding: 5px !important;

      .fp_tile_icon_cntnr {
        padding: 8vh 25vw !important;
      }
    }
  }

  @media (max-width: 550px) {
    align-items: center;
  }
}

.fp_input_suffix {
  @include themify {
    background-color: $transparent !important;
    // border: none;
    border-radius: 12px;
    color: themed('defult-text-color');
    border-color: $purple !important;

    .ant-input {
      // background-color: themed('background-menu-opacity-20');
      background-color: $transparent !important;
      color: themed('defult-text-color');
      // border: 1px $white solid;

      &:focus() {
        box-shadow: none;
      }
    }
  }
}

.fp_dymmy_form_item {
  .ant-form-item-control-input {
    min-height: 0;
  }
}

.fp_input {
  @include themify {
    background-color: $transparent !important;
    border-radius: 12px;
    color: themed('defult-text-color');
    height: 40px;
    border: 1.5px solid $purple;

    .ant-input {
      background-color: themed('background-menu-opacity-20');
      border: 1.5px $white solid;
      border-radius: 12px;

      &:focus() {
        box-shadow: none;
      }
    }
  }
}

.fb_picker_input {
  @include themify {
    background-color: $white-10 !important;
    border-radius: 12px;
    color: $white !important;
    height: 40px;
    width: 200px;
    border: 1.5px solid $white-20;
    margin-left: 10px;

    .ant-form-item-required {
      color: $purple;
    }

    .ant-form-item-label {
      color: $white-70 !important;
    }

    .ant-picker-header {
      color: $black !important;
    }

    .ant-picker-input {
      input {
        color: $white !important;
      }

      &:focus() {
        box-shadow: none;
      }

      .ant-picker-clear {
        &:hover {
          background: none !important;
        }
      }
    }
  }
}

.fb_select {
  @include themify {
    background-color: $white-10 !important;
    border-radius: 12px;
    color: $white !important;
    height: 40px;
    width: 200px !important;
    border: 1.5px solid $white-20;
    margin-left: 10px;
  }
}

.fb_select-max-width {
  @include themify {
    background-color: $white-10 !important;
    border-radius: 12px;
    color: $white !important;
    height: 40px;
    border: 1.5px solid $white-20;
    margin-left: 10px;
  }
}

.fp_table_input {
  @include themify {
    background-color: rgba(0, 0, 0, 0.4) !important;
    color: themed('defult-text-color');
    border: none;

    .ant-input {
      background-color: themed('background-menu-opacity-20');

      &:focus() {
        box-shadow: none;
      }
    }
  }

}

.account-menu {
  @include themify {
    border-radius: 200px !important;
    background-color: themed('background-menu-opacity-30');

    .ant-dropdown-menu-item {
      color: themed('defult-text-color');
      background-color: #464449 !important;
      border-radius: 200px !important;
      // &:hover {
      //   background-color: themed('background-menu-opacity-20');
      // }
    }
  }
}

.test-status-square {
  @include themify {
    background-color: themed('background-menu-opacity-20');
    border-radius: 20px;
    padding: 10px 35px;
    color: themed('defult-text-color');
    box-shadow: 0 2px 4px 0 rgba(17, 17, 17, 0.2), 0 2px 5px 0 rgba(11, 11, 11, 0.19);
  }

  .fp_status-tile {
    height: 80px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 17px;

    &_name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }
  }

  .fp_status-tile-right {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 500;
    font-size: 17px;
  }
}

.fp-drawer {
  @include themify {
    .ant-drawer-content {
      background-color: $transparent;
      backdrop-filter: blur(50px);
    }

    .ant-drawer-title {
      color: themed('black-60');
    }

    .ant-drawer-header {
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(25px);
      border: none;
      padding: 8px 24px;
    }

    .execution-item {
      padding: 15px 10px;
      margin: 5px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      box-shadow: 5px;

      .execution-item-title {
        color: themed('defult-text-color');
      }

      .execution-item-desc {
        color: themed('defult-text-color');
      }

      p {
        cursor: pointer;
      }
    }
  }
}

.dry-run-tag {
  @include themify {
    background-color: transparent;
    color: #FFFF;
    border: 1px solid #FFFF;
    font-size: 12px;
    font-weight: 500;
    margin-left: 8px;
  }
}

// #fp_testcase_summary_max_user {
// cursor: pointer;
// }

.max-user-modal {
  @include themify {
    .ant-modal-footer {
      border-top: none;
      padding: 0px 0px !important;
    }

    .ant-modal-title {
      color: themed('input-text-color');
    }

    .ant-modal-header {
      background: transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      padding: 16px 24px 8px;
    }

    .max-user-item {
      cursor: pointer;
      margin: 5px 0;
      backdrop-filter: blur(25px);
      border-radius: 25px;
      box-shadow: 5px;
      padding: 10px 10px 5px 15px;

      span {
        svg {
          width: 2em;
          height: 2em;
        }
      }
    }

    .fp_mas_row {
      background-color: rgba(255, 255, 255, 0.4);
    }

    .max-user-item-passed {
      background-color: rgba(128, 255, 128, 0.4);
    }

    .max-user-item-failed {
      background-color: rgba(221, 51, 51, 0.4);
    }

    .ant-collapse {
      text-align: left !important;
      background: transparent !important;
    }

    .ant-collapse-content {
      background: transparent !important;
    }

    .ant-collapse-header {
      border-bottom: 1.5px solid rgba(255, 255, 255, 0.4) !important;
      padding: 6px 16px !important;
      margin-bottom: 14px;
    }
  }
}

.fp-drop-down-button {
  @include themify {
    border: none;
    background: none;

    span {
      svg {
        width: 1.5em;
        height: 1.5em;
        fill: themed('defult-text-color');
      }
    }
  }
}

.fp-drop-down-menu {
  padding: 0 !important;

  @include themify {
    background: $grey-3;
    border-radius: 12px;

    .ant-dropdown-menu-item {
      color: themed('defult-text-color');

      button {
        display: flex;
        align-items: center;
        text-decoration: none;

        svg {
          height: 16px !important;
          margin-right: 10px !important;
        }
      }

      &:hover {
        background-color: themed('background-menu-opacity-20');
        border-radius: 12px !important;
      }
    }
  }
}

.upload-test-data-component {
  .ant-upload-list-item-card-actions {
    display: none;
  }
}

.execution-drawer-execution-status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .fp_test_status_without_color {
    margin-left: 10px;
    font-size: 20px !important;
  }

  .fp_test_status_success {
    margin-left: 10px;
    font-size: 20px !important;
    color: $state-color-3 !important;
  }

  .fp_test_status_error {
    margin-left: 12px;
    font-size: 20px !important;
    color: $state-color-2 !important;
  }

  .fp_test_status_pending {
    margin-left: 10px;
    font-size: 20px !important;
    color: $state-color-1 !important;
  }

  .fp_test_status_saved {
    margin-left: 10px;
    font-size: 20px !important;
    color: $state-color-4 !important;
  }
}

.breadcrumb-item {
  font-size: 12px;
  color: $white !important;
  background-color: $white-20 !important;
  padding: 8px 12px;
  border-radius: 12px;
}

.bread-crumb-seperator .ant-breadcrumb-separator {
  color: $white !important;
}

.fp_reqrmnt_rw {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: $black-40;
  padding: 15px;
  border-radius: 10px;

  &-Pay {
    border: 1px solid $linear-gradient-green;
  }

  &-Professional {
    border: 1px solid $linear-gradient-pink;
  }

  &-Enterprise {
    border: 1px solid $linear-gradient-yellow;
  }
}

.fp_reqrmnt_rw-Professional {
  border: 2px solid $linear-gradient-green !important;
}

.fp_tbl_rw {
  margin-top: 10px;
  margin-bottom: 10px;

  .ant-table {
    background: transparent !important;
  }

  .ant-table-thead>tr>th {
    text-align: center !important;
    border-inline: 1px solid $white-40;
    border: 1px solid $white-40;
  }

  .ant-table-thead>tr:first-child th:first-child {
    border-top-left-radius: 12px !important;
  }

  .ant-table-thead>tr:first-child th:last-child {
    border-top-right-radius: 12px !important;
  }

  .ant-table-tbody>tr:last-child td:first-child {
    border-bottom-left-radius: 12px !important;
  }

  .ant-table-tbody>tr:last-child td:last-child {
    border-bottom-right-radius: 12px !important;
  }

  .ant-table-tbody>tr.ant-table-row>td {
    height: unset !important;
  }

  .ant-table-tbody>tr>td {
    border-inline: 1px solid $white-40;
    border: 1px solid $white-40;
  }
}

.fp_hdn_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fp_sls-frm {
  top: 4% !important;
}

.fp_middle_notification_model {
  top: 340px !important
}

.fp_autocomplete_input {
  .ant-select-selector {
    background-color: rgba(0, 0, 0, 0.4) !important;
    color: #000;
    /* Replace with your desired text color */
    border: none !important;

    &:focus,
    &:hover {
      border-color: transparent;
      box-shadow: none;
    }

    &.ant-select-selector-open {
      .ant-select-selection-item {
        background-color: rgba(0, 0, 0, 0.1);
        /* Replace with your desired background color */
      }
    }
  }
}

.new-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.new-loader-bar {
  height: 15px;
  width: 6px;
  margin: 0 3px;
  border-radius: 20px;
  animation: loader 2s ease-in-out infinite;
}

.bar-1 {
  background: linear-gradient(to right, #00e6e6, #00ccff, #0099ff, #0066ff);
  animation-delay: 0s;
  box-shadow: 0px 0px 15px 3px #00e6e6;
}

.bar-2 {
  background: linear-gradient(to right, #00ccff, #0099ff, #0066ff, #00e6e6);
  animation-delay: 0.1s;
  box-shadow: 0px 0px 15px 3px #00ccff;
}

.bar-3 {
  background: linear-gradient(to right, #0099ff, #0066ff, #00e6e6, #00ccff);
  animation-delay: 0.2s;
  box-shadow: 0px 0px 15px 3px #0099ff;
}

.bar-4 {
  background: linear-gradient(to right, #0066ff, #00e6e6, #00ccff, #0099ff);
  animation-delay: 0.3s;
  box-shadow: 0px 0px 15px 3px #0066ff;
}

@keyframes loader {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }

  100% {
    transform: scaleY(1);
  }
}

.right-border {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.column-align {
  justify-content: center !important;
  text-align: center !important;
}

.column-align-right {
  justify-content: right !important;
  text-align: right !important;
}

.customize-columns {
  justify-content: left !important;
}

.customize-text {
  &-completed {
    font-weight: bold !important;
    color: rgba(34, 169, 134, 1) !important;
  }

  &-inprogress {
    font-weight: bold !important;
    color: rgba(255, 185, 0, 1) !important;
  }

  &-paused {
    font-weight: bold !important;
    color: $white-50 !important;
  }
}

.max-height-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.namecontainer {
  &:hover {
    color: $white-10 !important;
  }
}

.sm-tooltip-container {
  .ant-tooltip {
    justify-content: center !important;
    align-items: center !important;
  }
}

.text-color{
  &-white{
    color: $white !important;
  }
}

.pagination-container {
  margin-top: 20px;

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.10);
    color: white;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.10);
    color: white;
  }

  .ant-pagination-item,
  a {
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.10);
    color: white;
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active a {
    border-radius: 5px;
    border: 1px solid var(--Solid-Secondary, #2EC6B2);
    background: rgba(255, 255, 255, 0.10);
    background-color: rgba(255, 255, 255, 0.10);
    color: var(--Solid-Secondary, #2EC6B2);
  }

  .nav-buttons {
    height: 32px;
    padding: 10px;
    align-items: center;
  }

  a[disabled] {
    background: rgba(34, 34, 34, 0.70);
    backdrop-filter: blur(5px);
  }

  .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
    background: rgba(255, 255, 255, 0.10) !important;
    background-color: rgba(255, 255, 255, 0.10) !important;
    backdrop-filter: blur(5px) !important;
  }
}

.apexcharts-canvas {
  width: 95% !important;
  svg{
    width: 100% !important;
  }
}

.ant-select-arrow span {
  color: #fff;
}

.browser-options {
  display: inline-flex !important;
  align-items: center !important;
}

.execution_result-div{
  width: 180px;
  height: 35px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.chart-container{
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 450px;
  border-radius: 12px;
  margin-top: -5px;
  border-color: rgba(255, 255, 255, 0.2);
}

.chart-y-label{
  transform: rotate(-180deg);
  transform-origin: left bottom;
  white-space: nowrap;
  writing-mode: vertical-lr;
  text-align: center;
  display: block;
  margin-left: 90% !important;
  margin-top: -40px;

}